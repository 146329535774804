import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// services
import { AuthenticationService } from '../service/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor (
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      
        const currentUser = this.authenticationService.currentUser();
        const requiredRoles = route.data['requiredRole'];

        if (currentUser && requiredRoles) {
          if(requiredRoles.includes(currentUser.data?.user.Rol.Descripcion)){
            return true;
          }else{
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            return false;
          }
        }

        if (currentUser) {
          return true;
        }


        // not logged in so redirect to login page with the return url
        this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}


/**
 * Ejemplo de validacion de Ruta por ROL
 {
    path: 'ruta-protegida',
    component: ComponenteProtegido,
    canActivate: [AuthGuard],
    data: {
        requiredRole: 'Administrador' // Reemplaza 'admin' con el rol requerido
    }
}
*/
