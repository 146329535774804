import { Component, Input, OnInit, SimpleChange } from '@angular/core';

// utitlity
import { changeBodyAttribute, getLayoutConfig } from '../../shared/helper/utils';

// service
import { EventService } from 'src/app/core/service/event.service';

// constant
import { EventType } from 'src/app/core/constants/events';
import { LayoutColor, LayoutType, LayoutWidth, MenuPositions, SideBarSize, SideBarTheme, TopbarTheme } from '../../shared/config/layout.model';

import { CookieService } from 'ngx-cookie-service';
import { LoaderService } from 'src/app/core/service/loader.service';
import { Variant } from 'src/app/pages/ui/base-ui/shared/base-ui.model';

@Component({
  selector: 'app-vertical-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class VerticalLayoutComponent implements OnInit {

  @Input() layoutColor: string = '';
  @Input() layoutWidth: string = '';
  leftbarPosition: string = "";
  leftbarColor: string = "";
  leftbarSize: string = "";
  topbarColor: string = "";
  showSidebarUserInfo: boolean = false;
  reRender: boolean = true;

  variants: Variant[] = ['success', 'success', 'success'];

  configStyle = {
    layoutColor: '',
    layoutType: '',
    layoutSize: '',
    leftbarPosition: '',
    leftbarColor:'',
    leftbarSize: '',
    showSidebarUserInfo: true,
    topbarColor: '',
  };

  constructor (
    private eventService: EventService,
    private cookieService: CookieService,
    public laderService:LoaderService
  ) { }

  ngOnInit(): void {

    const cookieExists = this.cookieService.check('configStyle');

    if (cookieExists) {
      const configStyleCookie = JSON.parse(this.cookieService.get('configStyle'));
      this.configStyle = configStyleCookie;
      this.layoutColor = configStyleCookie.layoutColor
      this.leftbarPosition = configStyleCookie.leftbarPosition;
      this.leftbarColor = configStyleCookie.leftbarColor;
      this.leftbarSize = configStyleCookie.leftbarSize;
      this.topbarColor = configStyleCookie.topbarColor;
      this.showSidebarUserInfo = configStyleCookie.showSidebarUserInfo;
    } else {
      let config = getLayoutConfig(LayoutType.LAYOUT_VERTICAL);
      this.configStyle =config;
      this.leftbarPosition = config.leftbarPosition;
      this.leftbarColor = config.leftbarColor;
      this.leftbarSize = config.leftbarSize;
      this.topbarColor = config.topbarColor;
      this.showSidebarUserInfo = config.showSidebarUserInfo;

      const expirationDate = new Date('9999-12-31');
      this.cookieService.set('configStyle', JSON.stringify(this.configStyle), expirationDate);
    }



    // listen to event and change the layout configuarations
    this.eventService.on(EventType.CHANGE_LEFTBAR_POSITION).subscribe(({ payload }) => {
      debugger
      this.leftbarPosition = payload.toString();
      this.configStyle.leftbarPosition =this.leftbarPosition;
      this.cookieService.set('configStyle', JSON.stringify(this.configStyle));
    });

    this.eventService.on(EventType.CHANGE_LAYOUT_COLOR).subscribe(({ payload }) => {
      debugger
      this.layoutColor = payload.toString();
      this.configStyle.layoutColor =this.layoutColor;
      this.cookieService.set('configStyle', JSON.stringify(this.configStyle));
    });

    this.eventService.on(EventType.CHANGE_LEFTBAR_COLOR).subscribe(({ payload }) => {
      debugger
      this.leftbarColor = payload.toString();
      this.configStyle.leftbarColor =this.leftbarColor;
      this.cookieService.set('configStyle', JSON.stringify(this.configStyle));
    });

    this.eventService.on(EventType.CHANGE_LEFTBAR_SIZE).subscribe(({ payload }) => {
      debugger
      this.leftbarSize = payload.toString();
      this.configStyle.leftbarSize =this.leftbarSize;
      this.cookieService.set('configStyle', JSON.stringify(this.configStyle));
    });

    this.eventService.on(EventType.CHANGE_TOPBAR_COLOR).subscribe(({ payload }) => {
      debugger
      this.topbarColor = payload.toString();
      this.configStyle.topbarColor =this.topbarColor;
      this.cookieService.set('configStyle', JSON.stringify(this.configStyle));
    });

    this.eventService.on(EventType.TOGGLE_SIDEBAR_USERINFO).subscribe(({ payload }) => {
      debugger
      this.showSidebarUserInfo = Boolean(payload);
      this.configStyle.showSidebarUserInfo =this.showSidebarUserInfo;
      this.cookieService.set('configStyle', JSON.stringify(this.configStyle));
    });
    this.changeLayoutConfig();
  }

  ngAfterViewInit() {
    changeBodyAttribute('data-layout-mode', LayoutType.LAYOUT_VERTICAL);
  }

  /**
   * changes layout configurations
   */
  ngOnChanges(changes: SimpleChange) {
    this._setRerender();
    this.changeLayoutConfig();
  }

  ngDoCheck(): void {
    this.changeLayoutConfig();
  }

  ngOnDestroy(): void {
    changeBodyAttribute('data-layout-mode', '', 'remove');
  }

  /**
   * controls re-rendering
   */
  _setRerender = () => {
    this.reRender = false;
    setTimeout(() => {
      this.reRender = true;
    }, 20);
  }

  /**
   * changes layout related options
   */
  changeLayoutConfig(): void {
    // light vs dark mode
    changeBodyAttribute('data-layout-color', this.layoutColor);

    // boxed vs fluid
    changeBodyAttribute('data-layout-size', this.layoutWidth);

    // scrollable menus
    changeBodyAttribute('data-leftbar-position', this.leftbarPosition);

    // left sidebar theme
    changeBodyAttribute('data-leftbar-color', this.leftbarColor);

    // left sidebar type
    changeBodyAttribute('data-leftbar-size', this.leftbarSize);

    // topbar theme
    changeBodyAttribute('data-topbar-color', this.topbarColor);
  }


  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
  }


}
