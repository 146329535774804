<div class="mt-5 mb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">

                <div class="text-center">
                    <div class="auth-logo">
                        <a [routerLink]="'/'" class="logo logo-dark ">
                            <span class="logo-lg">
                                <img src="assets/images/logo-dark.png" alt="" height="100">
                            </span>
                        </a>

                        <a [routerLink]="'/'" class="logo logo-light ">
                            <span class="logo-lg">
                                <img src="assets/images/logo-light.png" alt="" height="100">
                            </span>
                        </a>
                    </div>
                    <h3 class="mt-4">Stay tunned, we're launching very soon</h3>
                    <p class="text-muted">We're making the system more awesome.</p>

                </div>
            </div>
        </div>
        <div class="row mt-5 justify-content-center">
            <div class="col-md-8 text-center">
                <div class="counter-number" [countDownDate]="countDownDate" [tick]="tick"
                    (counterChanged)="countDown($event)" *ngIf="!isCompeted(); else finished">
                    <div class="coming-box"> {{days ? days : '00'}}<span>Days</span></div>
                    <div class="coming-box"> {{hours ? hours : '00'}}<span>Hours</span></div>
                    <div class="coming-box"> {{minutes ? minutes : '00'}}<span>Minutes</span></div>
                    <div class="coming-box">{{seconds ? seconds : '00'}}<span>Seconds</span></div>
                </div>
                <ng-template #finished>
                    <div class="d-flex justify-content-center">
                        <h2>Coming Soon</h2>
                    </div>
                </ng-template>
            </div> <!-- end col-->
        </div> <!-- end row-->

    </div>
</div>
