<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                {{today | date:'yyyy'}} &copy; desarrollado por <a href="https://citytransportes.com/"
                    target="_blank">City Transportes</a>
            </div>
            <div class="col-md-6 text-end">
                <strong>Version: {{version}}</strong>
            </div>
           <!--<div class="col-md-6">
                <div class="text-md-end footer-links d-none d-sm-block">
                    <a href="javascript:void(0);">About Us</a>
                    <a href="javascript:void(0);">Help</a>
                    <a href="javascript:void(0);">Contact Us</a>
                </div>
            </div>-->
        </div>
    </div>
</footer>
